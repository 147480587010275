<template>
  <trac-loading v-if="loading"></trac-loading>
  <div v-else>
    <trac-back-button>Home</trac-back-button>
    <h1 class="mt-3 font-medium text-xl">Savings Waitlist</h1>
    <h4 class="mt-1 text-primaryGray">
      Be part of the first group of merchants to take part in Traction Savings.
    </h4>
    <div class="bg-white mt-8 lg:py-10">
      <div class="lg:max-w-3xl mx-auto">
        <div
          v-if="hasRegistered"
          style="background: #eaf3e6; border-color: #07912e"
          class="flex flex-col mt-3 mb-12 text-primaryBlue rounded text-sm justify-center items-center border py-3"
        >
          <div>Congratulations! 🎉🎉</div>
          <div class="mt-1">You are already on the waitlist</div>
        </div>
        <h1 class="text-primaryBlue text-center font-medium text-lg">
          About Traction Savings
        </h1>

        <img class="mt-5" src="../../assets/images/savings.png" alt="" />

        <p class="mt-5 tracking-wide leading-6">
          Save a fixed amount or percentage of your daily transactions, choose
          how long you want to save for, and monitor your progress as you save!
        </p>

        <h1 class="text-primaryBlue font-medium text-lg mt-8">Benefits</h1>

        <div class="flex items-center mt-3">
          <img src="../../assets/images/Transaction.png" alt="" />
          <div>
            <h2 class="font-medium text-lg">Easy to Use</h2>
            <p class="text-primaryGray text-sm">
              Set up your plan once and savings continue immediately.
            </p>
          </div>
        </div>

        <div class="flex items-center mt-2">
          <img src="../../assets/images/Transactioncircle.png" alt="" />
          <div>
            <h2 class="font-medium text-lg">Attractive Returns</h2>
            <p class="text-primaryGray text-sm">
              You get interest on your money after saving for a while.
            </p>
          </div>
        </div>
        <div class="flex items-center mt-2">
          <img src="../../assets/images/Transaction.png" alt="" />
          <div>
            <h2 class="font-medium text-lg">Automated Deductions</h2>
            <p class="text-primaryGray text-sm">
              Once your savings plan is set, just watch your money grow.
            </p>
          </div>
        </div>

        <div class="flex items-center justify-center mt-12">
          <trac-button :disabled="hasRegistered" @click.native="makeRequest">
            <div class="text-sm px-12">Join waitlist</div>
          </trac-button>
        </div>
      </div>
    </div>

    <trac-modal v-if="joinWaitlist" @close="joinWaitlist = false">
      <div class="flex justify-center items-center mt-16">
        <svg
          class=""
          width="59"
          height="59"
          viewBox="0 0 79 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="39.5"
            cy="39.5"
            r="39.0123"
            fill="#11A529"
            fill-opacity="0.12"
            stroke="#B2E7FF"
            stroke-width="0.975309"
          />
          <path
            d="M19.75 40.4745L27.445 50.1012C28.2562 51.116 29.7473 51.2557 30.7328 50.4092L58.7623 26.3325"
            stroke="#253B95"
            stroke-width="3.90123"
          />
        </svg>
      </div>
      <div class="text-center mt-10 leading-6">
        Congratulations. <br />
        You have successfully joined the savings <br />
        waitlist.
      </div>
      <div class="flex items-center justify-center mt-10 mb-8">
        <trac-button @click.native="(joinWaitlist = false), getRequest()">
          <div class="text-sm px-12">Go Home</div>
        </trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      joinWaitlist: false,
      hasRegistered: false,
      loading: false,
    };
  },
  methods: {
    async makeRequest() {
      this.loading = true;
      const response = await this.$store.dispatch('MAKE_WAITLIST_REQUEST');
      if (response && response.status) {
        this.joinWaitlist = true;
        this.hasRegistered = true;
      }
      this.loading = false;
    },
    async getRequest() {
      this.loading = true;
      const response = await this.$store.dispatch('GET_WAITLIST_BY_ID');
      if (response && response.status) {
        this.hasRegistered = true;
      }
      this.loading = false;
    },
  },
  async created() {
    await this.getRequest();
  },
};
</script>
